import React from "react";
import {Container, Row, Col} from 'react-bootstrap';

const TermsOfService = () =>{
    return(
        <>
    <div className="termsOfService">
        <Container>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <h1 className="green">
        Terms of Service
        </h1>
            <p>
                Introduction
                Welcome to Webpopdev LTD ("we," "our," "us"). These Terms of Service ("Terms") govern your use of our website, services, and products ("Services"). By accessing or using our Services, you agree to be bound by these Terms.
                <br></br>
                <br></br>
                Services
                Description: We offer web development services, including but not limited to website design, development, maintenance, and support.
                Eligibility: You must be at least 18 years old to use our Services.
                Accounts: You may be required to create an account to use certain features of our Services. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                <br></br>
                <br></br>
                
                Payment
                Fees: You agree to pay all fees associated with the Services in accordance with our pricing and payment terms.
                
                <br></br>
                <br></br>
                
                Payment Methods: We accept payments via Stripe and other payment methods as indicated on our website.
                
                <br></br>
                <br></br>
                
                Late Payments: Late payments may incur additional charges as outlined in our payment terms.
                <br></br>
                <br></br>
                
                Intellectual Property
                Ownership: We retain all intellectual property rights in our Services and content. You may not use, reproduce, or distribute any content without our prior written permission.
                Client Content: You retain all rights to any content you provide to us for the purpose of delivering our Services. You grant us a non-exclusive, royalty-free license to use your content for the duration of our engagement.
                <br></br>
                <br></br>
               
                Termination
                By You: You may terminate your use of our Services at any time by providing written notice to us.
                <br></br>
                
                By Us: We reserve the right to terminate or suspend your access to our Services for any reason, including violation of these Terms.
                <br></br>
                <br></br>
                
                Limitation of Liability
                Disclaimer: Our Services are provided "as is" without warranties of any kind, either express or implied.
                <br></br>
                
                Limitation: To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
                Governing Law
                These Terms are governed by and construed in accordance with the laws of United Kingdom, without regard to its conflict of laws principles.
                <br></br>
                <br></br>

                Changes to Terms
                We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Services after such changes will constitute your acceptance of the new Terms.
                <br></br>
                <br></br>

                Contact Us
                If you have any questions about these Terms, please contact us at contact@webpopdev.com.


            </p>
                </Col>
            </Row>
        </Container>
        
        </div>
        
        </>
    )
}


export default TermsOfService;