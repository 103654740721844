import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './styles/contact.css';
import EnquiryForm from '../components/contactform';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ContactUs = () => {
    return(

        <>
            <div className='contact-page'>
                <Container>
                    <Row>
                        <Col xl={12} md={12} sm={12} xs={12}>
                            <EnquiryForm headerText="Contact Us"></EnquiryForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>

    )
}


export default ContactUs;