import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/homepage.css';
import Company from './company';
import Services from './services';
import Packages from './packages';
import {Helmet} from 'react-helmet';
import EnquiryForm from '../components/contactform';
import Typewriter from 'typewriter-effect';
import "animate.css/animate.compat.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Homepage = () => {
    return(
        <>
        <div>
            <Helmet>
                <title>Web Development Agency - Webpopdev</title>
                <meta name="description" content="A Web Development agency offering Bespoke and high quality Website solutions for all sizes of businesses whilst being affordable."></meta>
            </Helmet>
        </div>
        <div className='homepage'>
            <Container>
                <Row>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                        <h1 class='white'>
                            Premium Website designs starting from <span class='orange'>£299.99 upfront</span>, with <span class='orange'><Typewriter
                            options={{
                                strings: ['1 Month Free Hosting', 'Unlimited Revisions'],
                                autoStart: true,
                                delay: 50,
                                loop: true,
                            }}
                            /></span>
                        </h1>
                            <h4>
                                We provide anything from basic websites for a small business, to fully functioning cross-platform application for big companies looking to provide
                                a service for their customers.
                                <br></br>
                                To get a free, no obligation quote on your project, please use the buttons below.
                            </h4>
                            <a href="https://calendly.com/webpopdev/30min"><button className='orange-btn'>Book a Call</button></a>
                            <a href="https://www.webpopdev.com/packages"><button className='green-btn'>Our Packages</button></a>
                        
                    </Col>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                        <EnquiryForm headerText="Enquire now to receive 10% off Any Premium Package"/>
                    </Col>
                </Row>
            </Container>
        </div>
        <Company></Company>
        <Services></Services>
        <Packages></Packages>
        
        </>
    )
}

export default Homepage;