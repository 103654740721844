
import React, { useEffect } from 'react';
import './index.css';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
/*import Contact from './views/contact.js';
import Packages from './views/packages';
import AdminLogin from './views/admin/AdminLogin.js';
import ContactForm from './views/youtube.js';
import FrontEnd from './views/frontEndDev.js';
import BackEnd from './views/backEndDev.js';
import SEO from './views/searchEngineOptimisation.js';
import SocialMedia from './views/socialMedia';
import Services from './views/services';*/
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './views/layout.jsx';
import Homepage from './views/homepage.js';
import Services from './views/services.js';
import Packages from './views/packages.js';
import intializeAnalytics from './analytics.js';
import usePageTracking from './usePageTracking.js';
import PaymentForm from './views/payment.js';
import PrivacyPolicy from './views/privacypolicy.js';
import TermsOfService from './views/termsofservice.js';
import ContactUs from './views/contact.js';

function App() {
  useEffect(() => {
    intializeAnalytics();
  }, []);



  return (
    <BrowserRouter>
    <PageTrackingWrapper>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Homepage />} />
          <Route path="/Services" element={<Services/>}/>
          <Route path="/Packages" element={<Packages/>} />
          <Route path="/Privacy-Policy" element={<PrivacyPolicy/>}/>
          <Route path="/Terms-Of-Service" element={<TermsOfService/>}/>
          <Route path="Contact-Us" element={<ContactUs/>}/>
        </Route>
      </Routes>
    </PageTrackingWrapper>
    </BrowserRouter>
  )
}


const PageTrackingWrapper = ({ children }) => {
  usePageTracking();
  return children;
};

export default App;



const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
)