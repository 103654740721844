import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './styles/privacypolicy.css';

const PrivacyPolicy = () => {
    return(
        <>
    <div className="privacyPolicy">
        <Container>
            <h1 className="green">
            Privacy Policy
            </h1>
            <Row>
                <Col xl={12} lg={12} sm={12} xs={12}>
                <p>
                    Introduction
                    [Webpopdev LTD] ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our website and Services.
                    <br></br>
                    <br></br>

                    Information We Collect
                    <br></br><br></br>
                    Personal Information: We may collect personal information that you provide to us, such as your name, email address, phone number, and payment information.
                    <br></br>
                    Usage Information: We may collect information about how you use our Services, including your IP address, browser type, and operating system.
                    <br></br><br></br>
                    
                    How We Use Your Information
                    <br></br>
                    To Provide Services: We use your information to provide, maintain, and improve our Services.
                    <br></br>
                    To Communicate: We may use your information to communicate with you, including sending you updates, promotional materials, and other information related to our Services.
                    <br></br>
                    To Process Payments: We use your payment information to process transactions related to our Services.
                    
                    <br></br><br></br>
                    Sharing Your Information
                    <br></br>
                    With Third Parties: We may share your information with third-party service providers who assist us in operating our business and providing our Services.
                    <br></br>
                    Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                    <br></br><br></br>
                    Security
                    We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no internet transmission is completely secure, and we cannot guarantee the absolute security of your information.
                    <br></br>
                    <br></br>

                    Your Rights
                    Access: You have the right to access the personal information we hold about you.
                    Correction: You have the right to request that we correct any inaccuracies in your personal information.
                    Deletion: You have the right to request that we delete your personal information, subject to certain exceptions.
                    Opt-Out: You have the right to opt out of receiving promotional communications from us.
                    Changes to This Policy
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of the Services after such changes will constitute your acceptance of the new Privacy Policy.
                    <br></br>
                    <br></br>

                    Contact Us
                    If you have any questions about this Privacy Policy, please contact us at contact@webpopdev.com.
                </p>
                </Col>
            </Row>
        </Container>
    </div>
        </>
    )
}


export default PrivacyPolicy;