import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/packages.css';
import "animate.css/animate.compat.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Packages = () => {
    return(
        <>
        <div className='packages'>
            <Container>
                <Row>
                    <Col xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="title">
                            <h3 className='blue'>
                                How much will it cost?
                            </h3>
                            <h1 className='blue'>
                                Our Prices
                            </h1>
                        </div>
                    </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-package">
                            <div className='top-section'>
                                <h2 className="package-title">
                                    Personal Starter Package
                                </h2>
                            </div>
                            <div className='middle-section'>
                                <h1 className="package-price">
                                    <span class="strikethrough">£399.99</span> £299.99
                                </h1>
                            </div>
                            <div className='bottom-section'>
                                <ul>
                                    <li><h4>Great for Individuals who want to get their information on google / use as a source of traffic.</h4></li>
                                    <li><h4>Includes one page of design and development.</h4></li>
                                    <li><h4>Unlimited revisions to ensure you are happy.</h4></li>
                                    <li><h4>Includes functional contact page for people to make enquiries.</h4></li>
                                    <li><h4>Hosting from just £9.99/m so you don't have to worry about whether your website is live.</h4></li>
                                    <li><h4>Responsive to all devices.</h4></li>
                                </ul>
                            </div>

                            <a href="https://pay.webpopdev.com/b/aEU7tj7mu0McarefZ1"><button className='orange-btn'>Enquire Now</button></a>
                        </div>
                    </ScrollAnimation>
                    </Col>
                    <Col lg={4} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                    <div class="container-package">
                            <div className='top-section'>
                                <h2 className="package-title">
                                    Business Starter Package
                                </h2>
                            </div>
                            <div className='middle-section'>
                                <h1 className="package-price">
                                <span class="strikethrough">£699.99</span> £599.99
                                </h1>
                            </div>
                            <div className='bottom-section'>
                                <ul>
                                    <li><h4>Great for Businesses who want to get their information on google / use as a source of traffic.</h4></li>
                                    <li><h4>Includes up to 5 pages of design and development.</h4></li>
                                    <li><h4>Unlimited revisions to ensure you are happy.</h4></li>
                                    <li><h4>Hosting from just £9.99/m so you don't have to worry about whether your website is live.</h4></li>
                                    <li><h4>Free Domain + Free 3 months Hosting and 3 Months Support.</h4></li>
                                    <li><h4>Responsive to all devices.</h4></li>
                                </ul>
                            </div>

                            <a href="https://pay.webpopdev.com/b/3cs4h78qy0McgPC3cd"><button className='orange-btn'>Enquire Now</button></a>
                        </div>
                    </ScrollAnimation>
                    </Col>
                    <Col lg={4} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-package">
                            <div className='top-section'>
                                <h2 className="package-title">
                                    Business Enterprise Package
                                </h2>
                            </div>
                            <div className='middle-section'>
                                <h1 className="package-price">
                                <span class="strikethrough">£1,699.99</span> £1,499.99
                                </h1>
                            </div>
                            <div className='bottom-section'>
                                <ul>
                                    <li><h4>Great for Businesses who want to take their marketing to the next level to increase their leads.</h4></li>
                                    <li><h4>Includes up to 10 pages of design and development. ( Can be done on CRM )</h4></li>
                                    <li><h4>Unlimited revisions to ensure you are happy.</h4></li>
                                    <li><h4>Free Domain + Free 3 months Hosting and 3 Months Support.</h4></li>
                                    <li><h4>Full SEO Performance package included so your website will rank consistently in google searches.</h4></li>
                                    <li><h4>Responsive on all devices.</h4></li>
                                </ul>
                            </div>

                            <a href="https://pay.webpopdev.com/b/9AQbJz5em0McbvibIK"><button className='orange-btn'>Enquire Now</button></a>
                        </div>
                    </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
        </>

    )
}

export default Packages;

