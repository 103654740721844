import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/footer.css'; // Optional: for additional custom styling
import '@fortawesome/fontawesome-free/css/all.min.css';
import Snapchat from './snapchat.jpeg';

const Footer = () => {
    return (
      <footer className="footer p-4">
        <Container>
          <Row>
            <Col md={4} sm={12} className="mb-3">
              <h5>About Us</h5>
              <p>
                We are a company providing Tailor-Made websites to take your busines to the next level based in the United Kingdom.
              </p>
            </Col>
            <Col md={4} sm={12} className="mb-3">
              <h5>Quick Links</h5>
              <ul className="list-unstyled">
                <li><a href="/home" className="text-white">Home</a></li>
                <li><a href="/about" className="text-white">About</a></li>
                <li><a href="/services" className="text-white">Services</a></li>
                <li><a href="/packages" className="text-white">Packages</a></li>
                <li><a href="/packages" className="text-white">Privacy Policy</a></li>
                <li><a href="/packages" className="text-white">Terms Of Service</a></li>
              </ul>
            </Col>
            <Col md={4} sm={12} className="mb-3">
              <h5>Contact Us</h5>
              <p>
                Email: contact@webpopdev.co.uk<br />
                Phone: +447765718094<br />
                Address: 61 Bridge Street, Kington, HR5 3DJ
              </p>
              <div>
                <a href="https://facebook.com/webpopdevelopment" className="text-white social-icon">
                  <i className="fab fa-facebook-f fa-2x"></i>
                </a>
                <a href="https://twitter.com/webpopdevelopment" className="text-white social-icon">
                  <i className="fab fa-twitter fa-2x"></i>
                </a>
                <a href="https://instagram.com/webpopdevelopment" className="text-white social-icon">
                  <i className="fab fa-instagram fa-2x"></i>
                </a>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-center">
              <p className="mb-0">© 2024 Web Pop Development. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  };

export default Footer;