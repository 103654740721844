import React, { useState } from 'react';
import axios from 'axios';

const EnquiryForm = ({headerText}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [businessInfo, setBusinessInfo] = useState('');
  const [status, setStatus] = useState('Submit');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setStatus('Sending Enquiry');
      await axios.post('https://webpopdev-server-0adced110176.herokuapp.com/send-enquiry', { name, email, phone, businessInfo });
      setStatus('Enquiry Sent');
    } catch (error) {
      setStatus('Error submitting enquiry');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
            <h3>
                {headerText}
            </h3>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Full Name' required/>
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email Address' required/>
                <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Phone Number' required/>
                <input type="text" value={businessInfo} onChange={(e) => setBusinessInfo(e.target.value)} placeholder='Business Information' required/>
                <button className='green-btn'>{status}</button>
                <a href="https://www.webpopdev.com/services"><button className='blue-btn'>Services</button></a>
    </form>
    
  );
};

export default EnquiryForm;
