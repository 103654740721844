import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51PLm9NBzeZOe1uzWTXVTRdgkHu7wS1R6kB0gBNrfnaP1baE8gIpU1Yy91MuBgBCcvWhVwFpEyXCk879bUeDG1taY00PT8Q6022');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { data: { clientSecret, amount, companyName, serviceDescription } } = await axios.post(' https://webpopdev-server-0adced110176.herokuapp.com:3000/create-payment-intent', {
      amount: 5000,  // example amount in cents
      companyName: 'Example Company',
      serviceDescription: 'Website Development Service'
    });

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: companyName
        }
      }
    });

    if (result.error) {
      setPaymentStatus(`Payment failed: ${result.error.message}`);
    } else if (result.paymentIntent.status === 'succeeded') {
      setPaymentStatus('Payment succeeded!');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
      <div>{paymentStatus}</div>
    </form>
  );
};

const PaymentForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default PaymentForm;
