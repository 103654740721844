import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/services.css';
import "animate.css/animate.compat.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Services = () => {
    return(
        <>
        <div className='services'>
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                            <div class="title">
                                <h3 className='white'>
                                    How can we help?
                                </h3>
                                <h1 className='white'>
                                    Our Services
                                </h1>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-service">
                            <h2 class="orange">
                                Web Design
                            </h2>
                            <h4 className='white'>
                                We always design your website first, to ensure we can iron out any changes you want before getting into the development of the website.
                                This saves time and ensures that your website is exactly how you want it before we bring it to life. We provide unlimited revisions of the design
                                so it doesn't matter how many changes you want to make! 
                            </h4>
                            <button class="orange-btn">Learn More</button>
                        </div>
                    </ScrollAnimation>

                    </Col>
                    <Col lg={6} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-service">
                            <h2 className="orange">
                                Web Development
                            </h2>
                            <h4 className='white'>
                               Once your are happy with the initial design, we will then get to work on the bringing your website to life. We will develop your website either from scratch,
                               or using a CRM depending on which option you pick. We thoroughly test each element of the website to ensure that everything works smoothly to give your users a seemless experience.
                            </h4>
                            <button class="orange-btn">Learn More</button>
                        </div>
                    </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-service">
                            <h2 class="orange">
                                Hosting & Maintenance
                            </h2>
                            <h4 className='white'>
                                We can host your website and get your domain setup for a small monthly cost to cover the hosting fees ( You would pay this same fee hosting the website yourself ). Allowing us to host the website ensures that in the event of anything going wrong, we have immediate access to restore the website. We also have monitoring tools in place to monitor the performance. 
                            </h4>
                            <button class="orange-btn">Learn More</button>
                        </div>
                    </ScrollAnimation>
                    </Col>
                    <Col lg={6} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-service">
                            <h2 className="orange">
                                SEO Optimisation
                            </h2>
                            <h4 className='white'>
                               SEO is one of the best forms of free marketing, having good SEO Optimisation ensures that your website appears high up on google searches and contains the correct information about your website on the google page. We always do our best to improve our SEO so that your website attracts the most customers to improve your conversion rates.
                            </h4>
                            <button class="orange-btn">Learn More</button>
                        </div>
                    </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-service">
                            <h2 class="orange">
                                Unlimited Revisions
                            </h2>
                            <h4 className='white'>
                                 We offer unlimited revisions during the design and development period of your new website. Whether you don't like the colour of a button, or you don't like the whole page, we will change it free of charge during this period. That way, you don't have to worry about not liking the website we give you, we always tailor the design to your needs.
                            </h4>
                            <button class="orange-btn">Learn More</button>
                        </div>
                    </ScrollAnimation>
                    </Col>
                    <Col lg={6} sm={12} xs={12}>
                    <ScrollAnimation animateOnce={true} animateIn='fadeInLeft'>
                        <div class="container-service">
                            <h2 className="orange">
                               Communications
                            </h2>
                            <h4 className='white'>
                                We have an open whatsapp channel which we can create for you after you purchase one of our services. We use this to communicate all our ideas and give you constant updates at where we are with your project. Once the website is live we will send statistics monthly for website viewers, uptime and downtime so you are never in the dark.
                            </h4>
                            <button class="orange-btn">Learn More</button>
                        </div>
                    </ScrollAnimation>
                    </Col>
                    
                </Row>
            </Container>
        </div>
        </>

    )
}

export default Services;

