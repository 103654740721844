import React from "react";
import {Outlet} from "react-router-dom";
import NavbarTop from "../components/navbar.js";
import Footer from "../components/footer.js";

const Layout = () => {
  return (
    <>
      <NavbarTop />
      <Outlet />
      <Footer/>
    </>
  );
};

export default Layout;