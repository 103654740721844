import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/company.css';
import officeWorking from '../views/images/officeWorking.jpg';

const Company = () =>{
    return(
        <>
            <div className='company'>
                <Container>
                    <Row>
                        <Col xl={6} lg={6} md={12} sm={12}>
                            <img src={officeWorking} alt="Office Working people"></img>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                            <h2>
                                Here at WebPopDev we ensure that we help you achieve the full
                                potential of having a Company Website.
                            </h2>
                            <h4>
                                We put all our effort into making sure your design suits your company needs,
                                we will listen to all your requests and come up with a solution that best fits.
                                We believe that having a modern design within your website
                                will boost your conversion rates. All our designs are 
                                completely responsive across ALL devices so that all users will have a good experience
                                when visiting. We ensure that your SEO is perfect so that your website will show up
                                higher in search results meaning more customers will view your webpage.
                            </h4>
                            <button>Enquire Now</button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </> 
    )
}

export default Company;