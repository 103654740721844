import React, { useState } from "react";
import { Component } from "react";
//import './styles/navbar.css';
import { BrowserRouter, Route, Link } from "react-router-dom";
//import {FaBars, FaTimes} from "react-icons/fa";
import {useRef} from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';
import axios from "axios";
import './styles/navbar.css';
import Logo from '../views/images/mainLogo.png';

function NavbarTop() {
    return(
            <Navbar className='navbar' fixed="top" expand="lg" bg="white" variant="black">
            <Container>
                <Navbar.Brand className='nav-main' href="/"><img src={Logo} alt="Website Logo"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll"/>
                <Navbar.Collapse id="navbarScroll">
                        <Nav className="ms-auto">
                            <Nav.Link className='nav-link' href="/">Homepage</Nav.Link>
                            <Nav.Link className='nav-link' href="Services">Services</Nav.Link>
                            <Nav.Link className='nav-link' href="Packages">Packages</Nav.Link>
                            <Nav.Link className='nav-link' href="Terms-Of-Service">Terms Of Service</Nav.Link>
                            <Nav.Link className='nav-link' href="Privacy-Policy">Privacy Policy</Nav.Link>
                            <Nav.Link className='nav-link' href="Contact-Us">Contact Us</Nav.Link>
                        </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
    )
}

export default NavbarTop;